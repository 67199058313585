.makeStyles-header-161.d-flex.position-relative.w-100 {
  height: 50px !important;
}

*, .container-fluid, .card > .card-body {
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
}

#map {
  width: 100%;
  height: 100vh;
}

#sidebar {
  position: absolute;
  background-color: #fff;
  z-index: 9999;
  border-radius: 0.3rem;
  margin: 0.3rem 0 0 0.3rem;
  padding: 0;
}

.MuiButtonBase-root.makeStyles-menuButton-121 span {
  padding: 15px !important;
}

/* .titleBarFirstBtn, 
.titleBarLastBtn, 
.titleBarNextBtn, 
.titleBarPreviousBtn {
  width: 50px;
} */

.tabTitleBar.componentWithEvents .titleCenter {
  width: 75%;
}

.titleBarLeftBtn button,
.titleBarRightBtn button {
  width: 50%;
}

.titleBarLeftBtn,
.titleBarRightBtn {
  width: 25%;
  margin-bottom: 5px;
}

.makeStyles-menuLogo-132.p-0 {
  padding: 7px !important;
  max-width: 20%;
  object-fit: contain;
}


